var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monthlyServiceCharges"},[_c('v-card',[_c('v-card-title',[_c('v-col',{staticClass:"col-md-2"},[_vm._v(" Monthly Service Fees ")]),_c('v-col',{staticClass:"col-md-1"},[_c('refresh-button',{attrs:{"loading":_vm.isMonthlyServiceChargeRefreshing},on:{"click":function($event){return _vm.refreshMonthlyServiceCharge()}}})],1),_c('v-col',{staticClass:"col-md-6"},[_c('table-filter',{attrs:{"table-name":'monthly service fees',"show-date-filter":false,"show-payment-status-filter":true,"show-month-filter":true},on:{"filter":_vm.applyFilters}})],1),_c('v-col',{staticClass:"col-md-3"},[_c('search-input',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.meterTokens,"loading":_vm.isMonthlyServiceChargeLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
        var items = ref.items;
return [(_vm.meterTokens.length > 0)?_c('tbody',_vm._l((items),function(monthlyServiceCharge){return _c('tr',_vm._g({key:monthlyServiceCharge.id,class:{ clickable: _vm.clickableRows }},_vm.clickableRows ? { click: function () { return _vm.onMonthlyServiceChargeClick(monthlyServiceCharge.id); } } : {}),[(_vm.clickableRows)?_c('td',[_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(monthlyServiceCharge.user.name))])]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm._f("formatMonth")(monthlyServiceCharge.month)))]),_c('td',[_vm._v(_vm._s(monthlyServiceCharge.service_charge))]),_c('td',[_c('v-chip',{class:{
                  'paid': monthlyServiceCharge.status === 1,
                  'not-paid': monthlyServiceCharge.status === 0,
                  'balance': monthlyServiceCharge.status === 2,
                  'over-paid': monthlyServiceCharge.status === 3,
                },attrs:{"text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("formatMeterReadingStatus")(monthlyServiceCharge.status))+" ")])],1)])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMonthlyServiceChargeLoading),expression:"!isMonthlyServiceChargeLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No Monthly Service Charge found ")])])])]}}])}),_c('v-divider'),_c('v-pagination',{staticClass:"mt-1",attrs:{"length":_vm.pagination.pageCount,"total-visible":8},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }