<template>
  <div class="monthlyServiceCharge">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <monthly-service-charge-table
      :monthly-service-charge-url="monthlyServiceChargeUrl"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiShapeSquarePlus,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import MonthlyServiceChargeTable from '@/components/tables/MonthlyServiceChargeTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    MonthlyServiceChargeTable,
  },
  data() {
    return {
      showAddMeterDialog: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Monthly Service Fee',
          disabled: true,
          to: { name: 'monthly-service-charge' },
        },
      ],
      icons: {
        mdiShapeSquarePlus,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    monthlyServiceChargeUrl() {
      return `monthly-service-charges?station_id=${this.selectedStation}`
    },
  },
}
</script>
