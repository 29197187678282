<template>
  <div class="monthlyServiceCharges">
    <v-card>
      <v-card-title>
        <v-col class="col-md-2">
          Monthly Service Fees
        </v-col>
        <v-col class="col-md-1">
          <refresh-button
            :loading="isMonthlyServiceChargeRefreshing"
            @click="refreshMonthlyServiceCharge()"
          />
        </v-col>
        <v-col class="col-md-6">
          <table-filter
            :table-name="'monthly service fees'"
            :show-date-filter="false"
            :show-payment-status-filter="true"
            :show-month-filter="true"
            @filter="applyFilters"
          />
        </v-col>
        <v-col class="col-md-3">
          <search-input v-model="search" />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="meterTokens"
        :loading="isMonthlyServiceChargeLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template
          v-slot:body="{
            items,
          }"
        >
          <tbody v-if="meterTokens.length > 0">
            <tr
              v-for="monthlyServiceCharge in items"
              :key="monthlyServiceCharge.id"
              :class="{ clickable: clickableRows }"
              v-on="clickableRows ? { click: () => onMonthlyServiceChargeClick(monthlyServiceCharge.id) } : {}"
            >
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ monthlyServiceCharge.user.name }}</span>
              </td>
              <td>{{ monthlyServiceCharge.month | formatMonth }}</td>
              <td>{{ monthlyServiceCharge.service_charge }}</td>
              <td>
                <v-chip
                  text-color="white"
                  :class="{
                    'paid': monthlyServiceCharge.status === 1,
                    'not-paid': monthlyServiceCharge.status === 0,
                    'balance': monthlyServiceCharge.status === 2,
                    'over-paid': monthlyServiceCharge.status === 3,
                  }"
                >
                  {{ monthlyServiceCharge.status | formatMeterReadingStatus }}
                </v-chip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isMonthlyServiceChargeLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No Monthly Service Charge found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <v-pagination
        v-model="pagination.page"
        class="mt-1"
        :length="pagination.pageCount"
        :total-visible="8"
        @input="onPageChange"
      ></v-pagination>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mdiSend } from '@mdi/js'
import moment from 'moment'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'

export default {
  components: { SearchInput, TableFilter, RefreshButton },
  props: {
    monthlyServiceChargeUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showConfirmDeleteDialog: false,
    showConfirmResendDialog: false,
    isConfirmDeleteDialogButtonLoading: false,
    isConfirmResendDialogButtonLoading: false,
    toDelete: null,
    sortBy: ['created_at'],
    sortDesc: [true],
    pagination: {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    },
    search: '',
    headers: [
      {
        text: 'Customer',
        value: 'user',
        sortable: false,
      },
      {
        text: 'Month',
        value: 'month',
        sortable: false,
      },
      {
        text: 'Service fee',
        value: 'service_charge',
      },
      {
        text: 'Status',
        value: 'status',
      },
    ],
    icons: {
      mdiSend,
    },
    meterReading: {},
    meterTokens: [],
    isMonthlyServiceChargeLoading: true,
    isMonthlyServiceChargeRefreshing: false,
    filters: {
      month: moment().format('YYYY-MM'),
      paymentStatus: [],
    },
  }),
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.monthlyServiceChargeUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getMonthlyServiceCharges()
    },
  },
  mounted() {
    this.getMonthlyServiceCharges()
    if (!this.clickableRows) {
      this.removeFirstTableHeader()
    }
  },
  methods: {
    getMonthlyServiceCharges: _.debounce(function () {
      this.isMonthlyServiceChargeLoading = true
      const status = JSON.stringify(this.filters.paymentStatus)
      axios
        .get(
          `${this.monthlyServiceChargeUrl}&month=${this.filters.month}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&status=${status}&page=${this.pagination.page}`,
        )
        .then(response => {
          this.meterTokens = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isMonthlyServiceChargeLoading = false
          this.isMonthlyServiceChargeRefreshing = false
        })
        .catch(error => {
          this.isMonthlyServiceChargeLoading = false
          this.isMonthlyServiceChargeRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshMonthlyServiceCharge() {
      this.isMonthlyServiceChargeRefreshing = true
      this.getMonthlyServiceCharges()
    },
    resendMonthlyServiceCharge() {
      this.isConfirmResendDialogButtonLoading = true
      axios
        .post(`monthly-service-charge-resend/${this.toResend}`)
        .then(() => {
          this.$notification.success(`Token sms has been resend to ${this.toResendCustomerName}`)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
    },
    onMonthlyServiceChargeClick(meterTokenId) {
      this.$router.push({ name: 'monthly-service-charge-details', params: { id: meterTokenId } })
    },
    removeFirstTableHeader() {
      const removeValFrom = [0]
      this.headers = this.headers.filter((value, index) => removeValFrom.indexOf(index) === -1)
    },
    onPageChange() {
      this.getMonthlyServiceCharges()
    },
    applyFilters(filters) {
      this.filters = filters
      this.getMonthlyServiceCharges()
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.v-chip.not-paid {
  background: #F44336;
}
.v-chip.paid {
  background: #4CAF50;
}
.v-chip.balance {
  background: #FFA726;
}
.v-chip.over-paid {
  background: #2196F3;
}
</style>
